import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    NavLink
} from "react-router-dom";
import './MainNavigation.css';

const MainNavigation = () => {
    return (
        <div className="navigation-bar">
            <NavLink className="navigation-link" to="/resume"><img className='menuIcon' src={process.env.PUBLIC_URL + '/images/resume.svg'} /><span>Resume</span></NavLink>
            <NavLink className="navigation-link" to="/profile"><img className='menuIcon' src={process.env.PUBLIC_URL + '/images/profile.svg'} /><span>Profile</span></NavLink>
            <NavLink className="navigation-link" to="/project"><img className='menuIcon' src={process.env.PUBLIC_URL + '/images/projects.svg'} /><span>Projects</span></NavLink>
            {/* <NavLink className="navigation-link" to="/contact"><img className='menuIcon' src={process.env.PUBLIC_URL + '/images/contact.svg'} /><span>Contact</span></NavLink> */}
        </div>
    )
}

export default MainNavigation;